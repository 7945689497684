import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { supplyOptions } from "./options";
import HasRolePermission from "components/shared/has-role-permission";
import NoMaskReasonInput from "./no-mask-reason-input";
import { PatientProfileContext } from "components/profile/context";
import { formatDate } from "utils/dates";

const ChooseSupplies = ({
  values,
  setFieldValue,
  patientHCPCHistory,
  eligibleEquipmentType,
  patientInfo
}) => {
  React.useEffect(() => {
    if (values.requested_equipment?.includes("mask")) {
      setFieldValue("reason_for_no_mask", "");
    }
  }, [values.requested_equipment]);
  const handleEquipmentOnChange = (thisItem, selectedEquipment, e, value) => {
    if (thisItem == null || thisItem.isEligible) {
      if (e.target.checked) selectedEquipment.push(value);
      else {
        const idx = values.requested_equipment.indexOf(value);
        selectedEquipment.remove(idx);
      }
    } else {
      if (!thisItem.isEligible) {
        if (e.target.checked) {
          if (
            confirm(
              "You are selecting an item that the patient may not be eligible for, would you like to continue?"
            )
          ) {
            selectedEquipment.push(value);
          } else {
            return;
          }
        } else {
          const idx = values.requested_equipment.indexOf(value);
          selectedEquipment.remove(idx);
        }
      }
    }
  };

  const { company_order_settings } = useContext(PatientProfileContext);

  const PopularEquipment = [
    "tubing",
    "disposable filter",
    "nondisposable filter",
    "seals",
    "mask",
    "headgear"
  ].filter(x => company_order_settings.equipment_type_options.includes(x));
  const BasicEquipment = [
    "tubing",
    "disposable filter",
    "nondisposable filter",
    "seals"
  ].filter(x => company_order_settings.equipment_type_options.includes(x));
  const eligibleEquipment = supplyOptions
    .filter(
      equip =>
        company_order_settings?.equipment_type_options?.includes(equip.value) &&
        eligibleEquipmentType.includes(equip.value)
    )
    .map(x => x.value);

  return (
    <FieldArray
      name="requested_equipment"
      render={selectedEquipment => (
        <>
          <div className="choose-supplies">
            <div style={{ display: "flex" }}>
              <ul>
                {supplyOptions
                  .filter(x =>
                    company_order_settings?.equipment_type_options?.includes(
                      x.value
                    )
                  )
                  .map(({ value, text, description }) => {
                    const thisItem = patientHCPCHistory?.find(phh =>
                      phh.productType
                        .toLowerCase()
                        ?.includes(value.toLowerCase())
                    );
                    return (
                      <li key={value}>
                        <label>
                          <div className="supply-checkbox-container">
                            <input
                              name="requested_equipment"
                              type="checkbox"
                              value={value}
                              checked={values.requested_equipment.includes(
                                value
                              )}
                              onChange={e =>
                                handleEquipmentOnChange(
                                  thisItem,
                                  selectedEquipment,
                                  e,
                                  value
                                )
                              }
                            />
                          </div>
                          <div>
                            <div style={{ display: "flex" }}>
                              <div>
                                {text}
                                {"   "}
                              </div>{" "}
                              {thisItem && (
                                <div
                                  className={
                                    thisItem.isEligible
                                      ? "eligible"
                                      : "notEligible"
                                  }
                                >
                                  {thisItem.isEligible
                                    ? "Eligible"
                                    : formatDate(
                                        thisItem.eligibilityDate,
                                        "MM/DD/YYYY"
                                      )}
                                </div>
                              )}
                            </div>
                            <div className="description">{description}</div>
                          </div>
                        </label>
                      </li>
                    );
                  })}
              </ul>

              <div className="eligbleDisclaimerParent">
                <div className="eligbleDisclaimer">
                  * Items automatically selected are based off perceived
                  eligibility from insurance rules
                </div>
              </div>
            </div>
            {!patientInfo?.new_setup && (
              <div className="choose-supplies-button-group">
                <button
                  onClick={() => {
                    setFieldValue("requested_equipment", eligibleEquipment);
                  }}
                  type="button"
                >
                  Eligible Items
                </button>
                <button
                  onClick={() => {
                    setFieldValue("requested_equipment", PopularEquipment);
                  }}
                  type="button"
                >
                  Popular
                </button>
                <button
                  onClick={() => {
                    setFieldValue("requested_equipment", BasicEquipment);
                  }}
                  type="button"
                >
                  Basic
                </button>
              </div>
            )}
            <HasRolePermission allowedRoles={["SleepCoach"]}>
              <NoMaskReasonInput
                requested_equipment={values.requested_equipment}
              />
            </HasRolePermission>
          </div>
        </>
      )}
    />
  );
};

ChooseSupplies.propTypes = {
  values: PropTypes.shape({
    requested_equipment: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  patientHCPCHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  eligibleEquipmentType: PropTypes.arrayOf(PropTypes.string),
  patientInfo: PropTypes.object.isRequired
};

export default ChooseSupplies;
